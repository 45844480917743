import { AngularFireDatabase } from '@angular/fire/database'
import { Injectable } from '@angular/core'
import * as firebase from 'firebase'
import { Observable } from 'rxjs'
import { AppUser, AppUserDto } from '../models/app-user.model'
import { map } from 'rxjs/operators'
import { app } from 'firebase'
import { AngularFireFunctions } from '@angular/fire/functions'

@Injectable()
export class UserService {
  constructor(private db: AngularFireDatabase, private cloudFunctions: AngularFireFunctions) {}

  save(user: firebase.User) {
    this.db.object('/users/' + user.uid).update({
      name: user.displayName,
      email: user.email,
    })
  }

  get(uid: string): Observable<AppUser> {
    return this.db
      .object<AppUser>('/users/' + uid)
      .snapshotChanges()
      .pipe(
        map(appUserSnapshots => {
          const data = appUserSnapshots.payload.val()
          const id = appUserSnapshots.payload.key
          return { id, ...data }
        })
      )
  }

  getAll(): Observable<AppUser[]> {
    return this.db
      .list<AppUser>('/users/')
      .snapshotChanges()
      .pipe(
        map(appUserSnapshots =>
          appUserSnapshots.map(appUserSnapshot => ({
            id: appUserSnapshot.payload.key,
            ...appUserSnapshot.payload.val(),
          }))
        )
      )
  }

  getAllOrganizations(): Observable<AppUser[]> {
    return this.db
      .list<AppUser>('/users/', ref => ref.orderByChild('isOrganization').equalTo(true)) // Szűrés az adatbázis lekérdezés szintjén
      .snapshotChanges()
      .pipe(
        map(appUserSnapshots =>
          appUserSnapshots.map(appUserSnapshot => ({
            id: appUserSnapshot.payload.key,
            ...appUserSnapshot.payload.val(),
          }))
        )
      )
  }

  updateOrganization(user: AppUserDto, userId: string, isOrganization: boolean) {
    this.db.object('/users/' + userId).update({
      isOrganization: isOrganization,
    })
  }

  deleteUser(userId: string): void {
    const deleteUser = this.cloudFunctions.httpsCallable('deleteUser')
    deleteUser({ userId }).subscribe(() => {
      console.log('user has been deleted')
    })
  }
}
