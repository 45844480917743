import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from 'app/services/auth.service'
import { Subscription } from 'rxjs'
import { environment } from '../../../../environments/environment'

declare const $: any
declare interface RouteInfo {
  path: string
  title: string
  icon: string
  class: string
  isAdmin?: boolean
  isOrganization?: boolean
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/event',
    title: 'Közelgő Események',
    icon: 'event',
    class: '',
    isAdmin: true,
    isOrganization: true,
  },
  {
    path: '/past-events',
    title: 'Múltbeli Események',
    icon: 'event',
    class: '',
    isAdmin: true,
    isOrganization: true,
  },
  {
    path: '/table-list',
    title: 'Felhasználó lista',
    icon: 'content_paste',
    class: '',
    isAdmin: true,
    isOrganization: false,
  },
  {
    path: '/donation',
    title: 'Támogatás',
    icon: 'volunteer_activism',
    class: '',
    isAdmin: true,
    isOrganization: false,
  },
  {
    path: '/past-donations',
    title: 'Múltbeli támogatások',
    icon: 'volunteer_activism',
    class: '',
    isAdmin: true,
    isOrganization: false,
  },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  appVersion = environment.version
  menuItems: any[]

  private subscriptions: Subscription[] = []

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.appUser$.subscribe(appUser => {
      if (!appUser) {
        this.menuItems = []
        return
      }

      if (appUser.isAdmin) {
        this.menuItems = ROUTES.filter(menuItem => {
          return menuItem.isAdmin === appUser.isAdmin
        })
        return
      }

      if (appUser.isOrganization) {
        this.menuItems = ROUTES.filter(menuItem => {
          return menuItem.isOrganization === appUser.isOrganization
        })
      }
    })
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe()
      })
    }
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false
    }
    return true
  }
}
